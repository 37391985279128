import { createTheme } from "@mui/material";
import { blueGrey, cyan, pink } from "@mui/material/colors";
import { dark } from "@mui/material/styles/createPalette";
import { defaultTheme } from "./default.theme";

export const darkTheme = createTheme({
    shape: {
        borderRadius: 15,
    },
    typography: {
        body2: {
            color: "#a9a9a9",
        },
    },
    palette: {
        mode: "dark",
        primary: {
            main: "#7315ce",
            light: "#8426df",
            dark: "#6204bd",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#69646d",
            light: "#7a757e",
            dark: "#58535c",
            contrastText: "#ffffff",
        },
        background: {
            default: "#222326",
            paper: "#3a3b3d",
        },
        text: {
            primary: "#ffffff",
            secondary: "#000000",
            disabled: "#69646d",
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },

    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    color: "#fff",
                },
                selected: {
                    color: "#000",
                    backgroundColor: "red",
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    background: "linear-gradient(to bottom, #0ab5a7,#8e25fa)",
                    transition: ".5s",
                    transform: "scale(.9)",
                    width: "80px",
                    height: "80px",
                    color: "#ffffff",
                    border: "none",
                    ":hover": {
                        cursor: "pointer",
                        color: "#fff",
                        background: "linear-gradient(to bottom, #2ad5c7,#ae45ff)",
                        fontSize: "1.45em",
                        width: "84px",
                        height: "84px",
                        boxShadow: "0 0 25px #2ad5c7",
                    },
                },
            },
        },

        MuiIconButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#7315ce",
                    "&.Mui-disabled": {
                        background: "#7315ce",
                        opacity: "0.5",
                    },
                    color: "#ffffff",
                    ":hover": {
                        backgroundColor: "#6204bd",
                    },
                },
                colorSecondary: {
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    color: "#ffffff",
                    ":hover": { backgroundColor: "rgba(55, 55, 55, 3)" },
                },
            },
        },

        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: "#69646d",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    background: "#555",
                    // borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            // background: "#555",
                            // zIndex: -100,
                        },
                        "&.Mui-error fieldset": {
                            borderColor: defaultTheme.palette.error.main,
                        },
                    },
                },
            },
        },
    },
});
