//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useState } from "react";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "typescript-cookie";

// Material ui Imports
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";

// Custom components imports (and hooks and helpers)
import { VTButton, VTLoginForm, VTLogo, VTNavBar } from "@virtus-tech-repository/virtus-tech-repository";
import { useAppDispatch } from "../../store/hooks";
import {
    LoginInputsState,
    ResetCodeInputFormState,
    ResetPasswordState,
    VTConfirmPasswordFormWithdRulesWarning,
    VTResetCodeFormWithResendMsg,
    VTResetPasswordForm,
} from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTForm";
import awsAuthHelper, {
    AuthStatePasswordChangeResult,
    AuthStateSignInResult,
    AuthStateTermsAndConditionsResult,
} from "../../helpers/awsAuthHelper";
import { setUser } from "../../store/slices/user.slice";
import sleep from "../../utils/sleep";

export function LoginContainer() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [checkingPreAuthenticatedUser, setCheckingPreAuthenticatedUser] = useState(true);

    const [selectedScreen, setSelectedScreen] = useState<
        "login" | "resetCode" | "inputCode" | "changePassword" | "termsAndConditions" | "lti_login"
    >("login");

    const [isWrongLoginDetails, setIsWrongLoginDetails] = useState<boolean>(false);

    const [createFirstTimePassword, setCreateFirstTimePassword] = useState<boolean>(false);

    const [userEmail, setUserEmail] = useState<string>("");
    const [userPassword, setUserPassword] = useState<string>("");

    const [inputResetCode, setInputResetCode] = useState<string>("");

    const [resetCodeEmailInputErrorMsg, setResetCodeEmailInputErrorMsg] = useState<string | undefined>(undefined);

    const [LTILoginError, setLTILoginError] = useState<boolean>(false);

    const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);

    // LTI Login Details
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const lti = query.get("lti");

    useEffect(() => {
        setResetCodeEmailInputErrorMsg(undefined);
        setIsWrongLoginDetails(false);
    }, [selectedScreen]);

    const authenticate = async () => {
        if (lti) {
            const ltiEmail = getCookie("email") ?? "";
            const ltiCookie = getCookie("token") ?? "";

            setSelectedScreen("lti_login");
            const LTILoginResponse = await awsAuthHelper.ltiLogin(
                ltiEmail,
                ltiCookie,
                (userId: string, userEmail: string, organisation: string) => {
                    dispatch(
                        setUser({
                            id: userId,
                            email: userEmail,
                            name: "",
                            organisation: organisation,
                        }),
                    );
                    navigate("/");
                },
            );

            if (LTILoginResponse === AuthStateSignInResult.LTI_LOGIN_FAIL) {
                setLTILoginError(true);
            }
        } else {
            try {
                await awsAuthHelper.checkUserCurrentlyAuthenticated(
                    (userId: string, userEmail: string, organisation: string) => {
                        dispatch(
                            setUser({
                                id: userId,
                                email: userEmail,
                                name: "",
                                organisation: organisation,
                            }),
                        );
                    },
                );
                setCheckingPreAuthenticatedUser(false);
            } catch (e) {
                setCheckingPreAuthenticatedUser(false);
            }
        }
    };

    // check if user is already authenticated
    useEffect(() => {
        (async () => await authenticate())(); //IIFE pattern
    }, []);

    return (
        <Grid
            container
            xs={12}
            sx={{
                marginTop: "100px",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            {checkingPreAuthenticatedUser ? (
                <Grid item sx={{ marginTop: "50px" }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    {selectedScreen === "lti_login" && (
                        <Grid item sx={{ marginTop: "50px" }}>
                            {LTILoginError ? <Typography> Login Error</Typography> : <CircularProgress />}
                        </Grid>
                    )}
                    {selectedScreen === "login" && (
                        <VTLoginForm
                            loadingSpinner={showLoadingSpinner}
                            inputFieldsProps={{
                                errorMessage: isWrongLoginDetails ? "wrong email or password entered" : undefined,
                                onInputsChanged: () => {
                                    if (isWrongLoginDetails) {
                                        setIsWrongLoginDetails(false);
                                    }
                                },
                            }}
                            sx={{ width: "470px", marginTop: "10px" }}
                            primaryButtonText={"login"}
                            secondaryButtonText={"forgot password"}
                            onSecondaryButtonClicked={function (formDataInputObj: LoginInputsState): void {
                                setUserEmail(formDataInputObj.login);
                                setSelectedScreen("resetCode");
                            }}
                            title={"Login"}
                            onSubmit={async function (formFieldsStateObject: LoginInputsState) {
                                setShowLoadingSpinner(true);

                                setUserEmail(formFieldsStateObject.login);
                                let result = await awsAuthHelper.signIn(
                                    formFieldsStateObject.login,
                                    formFieldsStateObject.password,
                                    (userId: string, email: string, organisation: string) => {
                                        dispatch(
                                            setUser({
                                                id: userId,
                                                name: "",
                                                email: email,
                                                organisation: organisation,
                                            }),
                                        );
                                    },
                                );

                                await sleep(2000);

                                if (result === AuthStateSignInResult.LOGIN_FAILURE) {
                                    setIsWrongLoginDetails(true);
                                    setShowLoadingSpinner(false);
                                } else {
                                    setIsWrongLoginDetails(false);
                                    setShowLoadingSpinner(false);
                                }

                                if (result === AuthStateSignInResult.LOGIN_RESET_PASSWORD) {
                                    setCreateFirstTimePassword(true);
                                    setShowLoadingSpinner(false);
                                    setSelectedScreen("changePassword");
                                }

                                // TODO: REMOVE THIS IN FUTURE, THIS IS TEMPORARY LOGIN FOR DEMO'ING PURPOSES!!!
                                if (result === AuthStateSignInResult.LOGIN_TERMS_AND_CONDITIONS) {
                                    // navigate("/");
                                    await authenticate();
                                }
                            }}
                        />
                    )}

                    {selectedScreen === "resetCode" && (
                        <VTResetPasswordForm
                            inputFieldsProps={{
                                errorMessage: resetCodeEmailInputErrorMsg,
                                initialEmailVal: userEmail,
                                onEmailChange(val) {
                                    setResetCodeEmailInputErrorMsg(undefined);
                                },
                            }}
                            primaryButtonText={"send email"}
                            secondaryButtonText={"back"}
                            onSecondaryButtonClicked={function (formDataInputObj: ResetPasswordState): void {
                                setSelectedScreen("login");
                            }}
                            title={"Reset Password"}
                            onSubmit={async function (formFieldsStateObject: ResetPasswordState) {
                                const result = await awsAuthHelper.checkUserExistsAndSendPasswordResetCode(
                                    formFieldsStateObject.email,
                                );
                                if (result) {
                                    setUserEmail(formFieldsStateObject.email);
                                    setSelectedScreen("inputCode");
                                } else {
                                    setResetCodeEmailInputErrorMsg("user doesn't exist");
                                }
                            }}
                            sx={{ width: "470px", marginTop: "10px" }}
                        />
                    )}

                    {selectedScreen === "inputCode" && (
                        <VTResetCodeFormWithResendMsg
                            displayResendContent
                            onSubmit={function (formFieldsStateObject: ResetCodeInputFormState): void {
                                if (!formFieldsStateObject.codeIsValid) return;
                                setInputResetCode(formFieldsStateObject.code);
                                setSelectedScreen("changePassword");
                            }}
                            onCancel={function (formFieldsStateObject: ResetCodeInputFormState): void {
                                setSelectedScreen("resetCode");
                            }}
                            onResend={async function () {
                                const result = await awsAuthHelper.checkUserExistsAndSendPasswordResetCode(userEmail);
                            }}
                            sx={{ width: "470px", marginTop: "10px" }}
                        />
                    )}

                    {/* TODO: handle change password success result correctly, wether it's first time password creation or password change */}
                    {selectedScreen === "changePassword" && (
                        <VTConfirmPasswordFormWithdRulesWarning
                            onSubmit={async (passwordIsValid: boolean, password?: string | undefined) => {
                                if (passwordIsValid && password) {
                                    setUserPassword(password);
                                    if (createFirstTimePassword) {
                                        const result = await awsAuthHelper.createPasswordFirstTime(password);

                                        if (result === AuthStatePasswordChangeResult.SUCCESS) {
                                            const signInResult = await awsAuthHelper.signIn(
                                                userEmail,
                                                password,
                                                () => {},
                                            );
                                            if (signInResult === AuthStateSignInResult.LOGIN_TERMS_AND_CONDITIONS) {
                                                setSelectedScreen("termsAndConditions");
                                            }
                                        }
                                    } else {
                                        const resetPasswordResult = await awsAuthHelper.resetPasswordIsSuccess(
                                            userEmail,
                                            inputResetCode,
                                            password,
                                        );

                                        if (resetPasswordResult === AuthStatePasswordChangeResult.SUCCESS) {
                                            await awsAuthHelper.signIn(
                                                userEmail,
                                                password,
                                                (userId: string, userEmail: string, organisation: string) => {
                                                    dispatch(
                                                        setUser({
                                                            id: userId,
                                                            email: userEmail,
                                                            name: "",
                                                            organisation: organisation,
                                                        }),
                                                    );
                                                },
                                            );
                                        } else if (
                                            resetPasswordResult ===
                                            AuthStatePasswordChangeResult.WRONG_CODE_ENETERD_ERROR
                                        ) {
                                        } else {
                                        }
                                    }
                                }
                            }}
                            onSecondaryButtonClicked={function (): void {
                                // setSelectedScreen("resetCode");
                                if (createFirstTimePassword) {
                                    setSelectedScreen("login");
                                } else {
                                    setSelectedScreen("inputCode");
                                }
                            }}
                            sx={{ width: "470px", marginTop: "10px" }}
                        />
                    )}

                    {selectedScreen === "termsAndConditions" && (
                        <>
                            <Typography variant="h6" sx={{ margin: "10px" }}>
                                Terms and Conditions for Digi Sim
                            </Typography>
                            <Paper
                                sx={{
                                    minWidth: "150px",
                                    maxWidth: "760px",
                                    padding: "10px",
                                    paddingBottom: "22px",
                                    height: "60vh",
                                }}
                            >
                                <Box component="div" sx={{ overflow: "scroll", height: "100%" }}>
                                    <Typography variant="body1" align="justify" style={{ padding: "20px" }}>
                                        Introduction. Welcome to our Virtual Hospital platform 2 week trial period. Our
                                        platform is designed to provide you with access to various software
                                        applications, tools, and resources to help you manage your business or personal
                                        needs. By accessing or using our platform, you agree to comply with these terms
                                        and conditions. If you do not agree with any of these terms, please do not use
                                        our platform.
                                        <br /> <br />
                                        Account Registration. To access our platform, you must create an account by
                                        providing us with your personal or business information. You must ensure that
                                        your account information is accurate and up-to-date. You are solely responsible
                                        for maintaining the confidentiality of your account login credentials and for
                                        all activities that occur under your account.
                                        <br /> <br />
                                        Data Collection. We collect various types of data from our users, including
                                        personal and non-personal information. Personal information includes your name,
                                        email address, phone number, and any other information that can be used to
                                        identify you. Non-personal information includes data that does not directly
                                        identify you, such as your IP address, device type, and usage statistics. We
                                        collect and use this data to provide you with our services, to improve our
                                        platform, and to comply with legal obligations. We may also use this data to
                                        send you promotional or marketing materials.
                                        <br /> <br />
                                        Data Ownership. You retain ownership of all data that you upload to our
                                        platform. However, by uploading data to our platform, you grant us a non-
                                        exclusive, worldwide, royalty-free license to use, reproduce, modify, and
                                        distribute your data for the purposes of providing our services and improving
                                        our platform.
                                        <br /> <br />
                                        Security. We take the security of your data very seriously and have implemented
                                        various measures to protect your data from unauthorized access, use, or
                                        disclosure. However, we cannot guarantee that our platform is completely secure,
                                        and we are not responsible for any data breaches or other security incidents
                                        that may occur.
                                        <br /> <br />
                                        Third-Party Services. Our platform may integrate with third-party services, such
                                        as payment processors or analytics tools. Your use of these services is subject
                                        to their own terms and conditions, and we are not responsible for any issues or
                                        disputes that may arise between you and these third-party providers.
                                        <br /> <br />
                                        Termination. We reserve the right to terminate your account or restrict your
                                        access to our platform at any time, with or without cause. You may also
                                        terminate your account at any time by contacting us.
                                        <br /> <br />
                                        Disclaimer of Warranties. Our platform is provided on an "as is" and "as
                                        available" basis. We do not make any warranties or guarantees regarding the
                                        availability, reliability, or functionality of our platform. We are not
                                        responsible for any errors, omissions, or interruptions in our platform or for
                                        any loss or damage that may result from your use of our platform.
                                        <br /> <br />
                                        Limitation of Liability. To the maximum extent permitted by law, we are not
                                        liable for any direct, indirect, incidental, or consequential damages arising
                                        from your use of our platform, including but not limited to, loss of profits,
                                        data, or other intangible losses.
                                        <br /> <br />
                                        Governing Law and Jurisdiction. These terms and conditions are governed by and
                                        construed in accordance with the laws of [insert applicable jurisdiction]. Any
                                        disputes arising from your use of our platform will be resolved in the courts of
                                        England and Wales, Scotland and Northern Ireland.
                                        <br /> <br />
                                        Changes to these Terms and Conditions. We reserve the right to modify these
                                        terms and conditions at any time, without prior notice. Your continued use of
                                        our platform after any changes to these terms and conditions will constitute
                                        your acceptance of the modified terms.
                                        <br /> <br />
                                        Contact Us. If you have any questions or concerns about these terms and
                                        conditions or our platform, please contact us at info@virtustech.co.uk
                                    </Typography>
                                </Box>
                            </Paper>
                            <VTButton
                                sx={{ marginTop: "20px" }}
                                onClick={async () => {
                                    const termsAndConditionsAcceptResult =
                                        await awsAuthHelper.acceptTermsAndConditions();

                                    if (termsAndConditionsAcceptResult === AuthStateTermsAndConditionsResult.ACCEPTED) {
                                        const signInResult = await awsAuthHelper.signIn(
                                            userEmail,
                                            userPassword,
                                            (userId: string, userEmail: string, organisation: string) => {
                                                dispatch(
                                                    setUser({
                                                        id: userId,
                                                        email: userEmail,
                                                        name: "",
                                                        organisation: organisation,
                                                    }),
                                                );
                                            },
                                        );
                                    }
                                }}
                            >
                                accept
                            </VTButton>
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
