export function getAudioClipDuration(url: string): number {
    let audioElement = document.createElement("audio");
    let audioSrc = document.createElement("source");
    audioElement.src = url;
    return audioElement.duration;
}

/**
 * returns a promise which when resolves returns the duration of the audio in milliseconds or 
 * rejects with the value if something goes wrong
 */
export function getDurationOfAudioInSeconds(src: string): Promise<number> {
    return new Promise((resolve, reject) => {
        var audio = new Audio();

        audio.addEventListener("loadedmetadata", function () {
            if (audio.duration) {
                resolve(audio.duration);
            } else {
                reject(audio.duration);
            }
        });
        audio.src = src;
    });
}